import React from 'react';
import { GetStaticProps } from 'next';
import PageNationalStandardTemplate from '@src/templates/PageNationalStandard';
import {
  GlobalSettingsQuery,
  NationalBasePageQuery
} from '@src/graphql/gql-types';
import {
  globalSettingsQuery,
  nationalBasePageQuery
} from '@src/graphql/queries';
import { apolloClient, setApolloHeaders } from '@src/services/apollo';
import { NationalStandardPageProps } from '@src/types/page';

type NotFound = NationalBasePageQuery;

const NotFound: React.FC<NotFound> = (props) => {
  return <PageNationalStandardTemplate {...props} notFound />;
};

export default NotFound;

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  if (preview) {
    setApolloHeaders(process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN);
  }

  const [globalSettingsQueryResponse, nationalBasePageQueryResponse] =
    await Promise.all([
      apolloClient.query<GlobalSettingsQuery>({
        query: globalSettingsQuery,
        variables: {
          preview: !!preview
        }
      }),
      apolloClient.query<NationalBasePageQuery>({
        query: nationalBasePageQuery,
        variables: {
          preview: !!preview
        }
      })
    ]);

  if (globalSettingsQueryResponse?.errors) {
    console.error(globalSettingsQueryResponse.errors);
  }

  if (nationalBasePageQueryResponse?.errors) {
    console.error(nationalBasePageQueryResponse.errors);
  }

  const templatePropsData: NationalStandardPageProps = {
    globalSettingsPayload: globalSettingsQueryResponse?.data,
    nationalBasePagePayload: nationalBasePageQueryResponse?.data
  };

  return {
    props: {
      ...templatePropsData
    }
  };
};
